// src/actions/chartActions.js
import axios from 'axios';
import { setStartYear, setFinishYear } from './yearActions';

export const fetchChartData = (indicatorId) => async (dispatch) => {
  console.log("Fetching chart data for indicator ID:", indicatorId);
  
  try {
    dispatch({ type: 'CHART_DATA_REQUEST' });

    const { data } = await axios.get(`https://orca-app-2ecqd.ondigitalocean.app/api/data/chart/indicator/${indicatorId}`);
    
    dispatch({
      type: 'CHART_DATA_SUCCESS',
      payload: data,
    });

    // Reset startYear and finishYear to null to show full range for new indicator
    dispatch(setStartYear(null));
    dispatch(setFinishYear(null));
    
  } catch (error) {
    dispatch({
      type: 'CHART_DATA_FAIL',
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};
