// src/components/Header.jsx
import React from 'react';
import './Header.css';
import logo from '../../../logo.jpeg';
import { NavLink } from 'react-router-dom';

const Header = () => {
  return (

    <header className="header">
    <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
        <span className="logo-text">Dados Para Ti</span>
      </div>
      <nav className="nav-links">
      <NavLink to="/" exact activeClassName="active">
        Página Principal
      </NavLink>
      <NavLink to="/about" activeClassName="active">
        Sobre Nós
      </NavLink>
      <NavLink to="/about#responsabilidade-dos-dados" activeClassName="active">
        Responsabilidade dos Dados
      </NavLink>
      <NavLink to="/feedback" activeClassName="active">
        Deixar Feedback
      </NavLink>
    </nav>
    </header>
  );
};

export default Header;
