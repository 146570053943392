// src/dashboardpg/computerbp/Downloadbtn3.jsx
import React from 'react';
import styled from 'styled-components';
import { FaDownload } from 'react-icons/fa6';

const DownloadButton = styled.button`
  position: absolute;
  top: 27.5vh;
  left: 80vw;
  width: 16vw;
  font-size: 5vw;
  height: 1.6em;
  background-color: #835CFF;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  font-weight: bold;

  &:active {
    background-color: #597695;
  }
`;

const Downloadbtn3 = () => {
  return (
    <DownloadButton>
      <FaDownload />
    </DownloadButton>
  );
};

export default Downloadbtn3;
