// src/actions/categoryActions.js

import axios from 'axios';

// Action types
export const SET_INDICATORS = 'SET_INDICATORS';
export const SET_SELECTED_INDICATOR = 'SET_SELECTED_INDICATOR';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_LOADING = 'SET_LOADING';

// Action to set loading state
export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  payload: isLoading,
});

// Action to set the selected category
export const setCategory = (categoryId) => ({
  type: SET_CATEGORY,
  payload: categoryId,
});

// Action to fetch indicators based on the selected category
export const fetchIndicators = (categoryId) => async (dispatch) => {
  dispatch(setCategory(categoryId)); // Set selected category
  dispatch(setLoading(true)); // Set loading to true at the start

  try {
    // Construct the API endpoint with the selected category ID
    const response = await axios.get(`https://orca-app-2ecqd.ondigitalocean.app/api/data/indicators/${categoryId}`);
    
    // Dispatch the action to set indicators in the Redux store
    dispatch({ type: SET_INDICATORS, payload: response.data });
  } catch (error) {
    console.error('Error fetching indicators:', error);
  } finally {
    dispatch(setLoading(false)); // Set loading to false after fetching is done
  }
};

// Action to set the selected indicator
export const setSelectedIndicator = (indicatorName) => ({
  type: SET_SELECTED_INDICATOR,
  payload: indicatorName,
});
