// src/chart/chartConfig.js

export default {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          labels: {
            color: '#597695'
          }
        },
        title: {
          display: true,
          text: 'Produto Interno Bruto (PIB) de Moçambique',
          color: '#597695',
          font: {
            size: 14
          }
        }
      },
      scales: {
        x: {
            title: {
            display: true,
            text: 'Ano',
            color: '#A5B6C9'
          },
          ticks: {
            color: '#A5B6C9'
          },
          grid: {
            color: 'rgba(165, 182, 201, 0.3)' // Lightened grid line color
          }
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Bilhões USD',
            color: '#A5B6C9'
          },
          ticks: {
            color: '#A5B6C9'
          },
          grid: {
            color: 'rgba(165, 182, 201, 0.3)'
          }
        }
      },
      elements: {
        line: {
          borderColor: 'rgba(131, 92, 255, 1)',
          backgroundColor: 'rgba(131, 92, 255, 0.2)',
          borderWidth: 2,
          fill: true,
          tension: 0.4
        },
        point: {
          radius: 0 // Smooth line effect
        }
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 10,
          bottom: 10
        }
      }
    }
  };
  