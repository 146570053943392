// src/components/Header.jsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header1.css';
import logo from '../../../logo.jpeg';

const Header1 = () => {
  return (
    <header className="pc-fb-header">
      <div className="pc-fb-logo-container">
        <img src={logo} alt="Logo" className="pc-fb-logo" />
        <span className="pc-fb-logo-text">Dados Para Ti</span>
      </div>
      <nav className="pc-fb-links">
      <NavLink to="/" exact activeClassName="active">
        Página Principal
      </NavLink>
      <NavLink to="/about" activeClassName="active">
        Sobre Nós
      </NavLink>
      <NavLink to="/about#responsabilidade-dos-dados" activeClassName="active">
        Responsabilidade dos Dados
      </NavLink>
      <NavLink to="/feedback" activeClassName="active">
        Deixar Feedback
      </NavLink>
    </nav>
    </header>
  );
};

export default Header1;
