// src/dashboardpg/computerbp/chart/SourceArea.jsx
import React from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const SourceArea = () => {

  const citation = useSelector(state => state.chart.chartData.citation);
  console.log("Citation from Redux:", citation);

  return (
    <div>
      {/* Main Source Box */}
      <div style={{
        position: 'absolute',
        top: '80vh',
        left: '25.3vw',
        width: '70.7vw',  // 70.7vw - 25.3vw
        height: '10vh',    // 92vh - 82vh
        border: '2px solid #EFF2F5',  // Define border color and thickness
        borderRadius: '8px',          // Optional rounded corners
        padding: '1vw',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#f9f9f9'
      }}>
        <span style={{
        fontSize: '1vw',               // Responsive font size
        fontWeight: 'bold',
        color: '#597695',
        whiteSpace: 'normal',             // Allow text to wrap within the box
        overflow: 'hidden',               // Prevent overflow
        textOverflow: 'ellipsis',         // Show ellipsis if text overflows
        wordBreak: 'break-word',          // Break long words to fit within the box
}}>
  Fonte: {citation || "MacroTrends. (2024). Mozambique GDP - Gross Domestic Product 1960-2023."} {/* Display citation on the same line */}
</span>

        
        <FaRegCopy 
  style={{
    position: 'absolute',
    bottom: '0.5vw',
    right: '0.5vw',
    fontSize: '4vh',
    color: '#597695',
    cursor: 'pointer',
    transition: 'color 0.3s'  // Smooth transition for color change
    }} 
    onMouseEnter={(e) => e.currentTarget.style.color = '#835CFF'}
    onMouseLeave={(e) => e.currentTarget.style.color = '#597695'}
/>
      </div>

      {/* Explanatory Text below the box */}
      <p style={{
        position: 'absolute',
        top: '91vh',  // Place right below the SourceArea box
        left: '25.3vw',
        width: '70.7vw',
        fontSize: '0.8vw',
        color: '#597695',
        margin: '0'
      }}>
        Curioso (a) sobre fontes e atribuição de dados? clique em 'Responsabilidade dos Dados' no cabeçalho..
        Citação baseada no modelo Harvard.
      </p>
    </div>
  );
};

export default SourceArea;
