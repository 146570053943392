// src/pages/About1.jsx
import React from 'react';
import StructureContainer from './components/computertablet/StructureContainer';
import Header from './components/computertablet/Header';

const About1 = () => {
  return (
    <div>
      <StructureContainer />
      <Header />
    </div>
  );
};

export default About1;
