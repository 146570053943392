// src/components/StructureContainer.jsx
import React from 'react';
import './StructureContainer.css';
import Simao from '../../../SimaoPic.jpg';
import People from '../../../PeoplePic.jpeg';

const StructureContainer = () => {
  return (
    <div className="structure-container">
      <div className="main-content-box">
        {/* Section 1 */}
        <div className="content-section">
          <h2>Saiba Mais <span className="purple-text">Sobre Nós</span></h2>
          <img src={Simao} alt="Simao" className="image-simao" />
          <p>DadosParaTi.com é uma iniciativa desenvolvida por mim, Simão Rafique Júnior. Concluí recentemente o meu mestrado na Newcastle University, Inglaterra, onde aprendi desde as bases da programação estatística e matemática computacional, até ao uso de algoritmos avançados para trabalhar com conjuntos de dados complexos e de grande dimensão.</p>
          <p>Possuo também a certificação IBM Data Science Professional Certificate e sou Certified Associate in Project Management (CAPM)®, que estudei para obter enquanto completava o mestrado.</p>
        </div>

        {/* Section 2 */}
        <div className="content-section">
          <h2>Porquê Dados Para Ti?</h2>
          <p>Tenho testemunhado o crescimento da Ciência de Dados em Moçambique, com um número cada vez maior de pessoas a ocupar posições nesta área e a participar em conferências relacionadas.</p>
          <p>No entanto, a maioria dos profissionais e eventos limitam-se a falar de dados como um ativo para melhorar decisões empresariais.</p>
        </div>

        {/* Section 3 */}
        <div className="content-section">
          <h2>Então, quem se preocupa com o lado não-empresarial dos dados?</h2>
          <img src={People} alt="People" className="image-simao" />
          <p>Tomar decisões empresariais não é o único uso dos dados. Pode-se usar dados demográficos para identificar as áreas prioritárias que necessitam de intervenção governamental, como a falta de infraestruturas hospitalares ou os elevados níveis de desnutrição aguda.</p>
          <p>Além disso, os dados ajudam a criar uma sociedade mais informada, que sabe como analisar a situação económica do país com clareza. Assim, é possível superar frases vagas, comuns entre os jovens, como “O país está bom” ou “O país não está bom”, e substituí-las por opiniões mais fundamentadas e sustentadas por dados.</p>
          <p>Ganhei várias bolsas de estudo, e parte desse sucesso deve-se ao conhecimento profundo que tenho sobre o meu país a nível estatístico.</p>

        </div>

        {/* Section 4 */}
        <div className="content-section">
          <h2>Qual Era o Desafio?</h2>
          <p>A informação estatística sobre o país está dispersa pela internet, principalmente em sites como FAO, OMS e Banco Mundial, geralmente em inglês e com uma complexidade significativa para aceder aos dados. </p>
          <p>Com o Dados Para Ti, a informação é apresentada em português e está organizada para que, em apenas três cliques, possa ver os dados em gráficos, em vez de precisar de descarregar ficheiros complexos como xls.</p>
          
        </div>

        {/* Section 5 */}
        <div id="responsabilidade-dos-dados" className="content-section">
          <h2>Responsabilidade e Atribuição de Dados</h2>
          <p>Não reivindicamos crédito pelos dados apresentados nem cobramos pelo seu acesso. Reconhecemos claramente que os dados foram retirados de bases de dados e adicionados à nossa plataforma para facilitar o acesso dos moçambicanos.</p>
          <p>Para cada conjunto de dados que apresentamos, indicamos a fonte logo abaixo do gráfico. Sabemos que muitos estudantes podem usar esses dados, por isso incluímos as referências no estilo Harvard, para facilitar o uso em trabalhos académicos.</p>
          <p>Apreciamos a sua opinião sobre a nossa iniciativa! Pode clicar em “Deixar Feedback” e faremos o possível para atender às suas expectativas.</p>
        </div>
      </div>
    </div>
  );
};

export default StructureContainer;
