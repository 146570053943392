import React, { useState } from 'react';
import './Indicators3.css';
import { FaChevronDown } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedIndicator } from '../../../../actions/categoryActions';
import { fetchChartData } from '../../../../actions/chartActions'; // Import fetchChartData
import LoadingCheckIcon3 from '../../LoadingCheckIcon3';

// Define isMobile function to detect mobile devices
const isMobile = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

const Indicators = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  
  const indicators = useSelector((state) => state.category.indicators); // Get indicators from Redux
  const selectedIndicator = useSelector((state) => state.category.selectedIndicator); // Get selectedIndicator from Redux

  const dataReady = indicators && indicators.length > 0;
  
  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (item) => {
    console.log("Item ID:", item.id, "Item Name:", item.name); // Log the full item for confirmation
    dispatch(setSelectedIndicator({ id: item.id, name: item.name })); // Dispatch both id and name
    dispatch(fetchChartData(item.id));
    setIsOpen(false); // Close dropdown
  };


  return (
    <div className="mb-ind-dropdown">
      <button
        onClick={!isMobile() ? toggleDropdown : undefined} // Use onClick on non-mobile devices
        onTouchStart={isMobile() ? toggleDropdown : undefined} // Use onTouchStart on mobile devices
        className="mb-ind-dropdown-button"
      >
        <span>{selectedIndicator.name || 'Escolha o indicador'}</span>
        <LoadingCheckIcon3 dataReady={dataReady} className="mb-loading-icon" />
        <FaChevronDown className="mb-ind-dropdown-icon" />
      </button>
      {isOpen && (
        <ul className="mb-ind-dropdown-menu">
          {indicators.map((item) => (
            <li
              key={item.id}
              className="mb-ind-dropdown-item"
              onClick={() => handleSelect(item)} // Handle selection with onClick
            >
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Indicators;
