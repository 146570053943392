import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../../../logo.jpeg';
import { HiMenu } from "react-icons/hi";
import { NavLink } from 'react-router-dom';

// Styled components

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 11vh; /* Header height */
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 4vw;                 // Position LogoContainer absolutely within its parent
  display: flex;                // Use flex layout
  align-items: center;          // Center vertically
  gap: 1.5vw;                     // Add space between logo and text

  img {
    height: 80%;
    max-height: 8vh;
  }
`;

const LogoText = styled.span`
  font-size: 6vw;
  font-weight: bold;
  padding-left: 2.5vw;
  color: #597695;
`;

const MenuContainer = styled.div`
  position: absolute;
  right: 4vw;               /* Positioned at the right at 96vw */
  display: flex;
  align-items: center;       /* Vertically center the icon */
  height: 100%;              /* Take full header height for alignment */
`;

// Menu icon styling to scale responsively
const StyledMenuIcon = styled(HiMenu)`
  font-size: 16vw;
  color: #597695;
  cursor: pointer;

`;

// Full-screen overlay menu
const FullScreenMenu = styled.div`
  position: fixed;
  top: 10vh;            /* Start 12.5vh from the top */
  left: 0;
  width: 100vw;           /* Full width */
  background-color: rgba(255, 255, 255, 0.95); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4vh;               /* Space between items */
  padding: 2vh 0;         /* Add padding for top and bottom spacing */
  z-index: 999;

  transition: opacity 0.4s ease, visibility 0.4s ease;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  
  /* Final line at the end of the menu */
  &::after {
    content: "";
    display: block;
    width: 100vw;
    height: 1px;
    background-color: #597695; /* Line color */
  }
`;

const MenuItem = styled.span`
  font-size: 5.5vw; /* Responsive font size */
  color: #597695;
  cursor: pointer;

    /* Horizontal line above each item */
  &::before {
    content: "";
    display: block;
    width: 80vw;
    height: 1px;
    background-color: #597695; /* Line color */
  }

`;



// Component

const Header2 = () => {

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <HeaderContainer>
        <LogoContainer>
          <img src={logo} alt="Logo" />
          <LogoText>Dados Para Ti</LogoText>
        </LogoContainer>
        <MenuContainer>
          <StyledMenuIcon onClick={toggleMenu} />
        </MenuContainer>

        {/* Full-screen menu overlay */}
        <FullScreenMenu isOpen={menuOpen}>
          <NavLink to="/" exact activeClassName="active">
            <MenuItem>Página Principal</MenuItem>
          </NavLink>
          <NavLink to="/about" activeClassName="active">
            <MenuItem>Sobre Nós</MenuItem>
          </NavLink>
          <NavLink to="/about#responsabilidade-dos-dados" activeClassName="active">
            <MenuItem>Responsabilidade dos Dados</MenuItem>
          </NavLink>
          <NavLink to="/feedback" activeClassName="active">
            <MenuItem>Deixar Feedback</MenuItem>
          </NavLink>
        </FullScreenMenu>
      </HeaderContainer>
    </>
  );
};

export default Header2;
