import React, { useState, useEffect } from 'react';
import './Years3.css';
import { FaChevronDown } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { setStartYear, setFinishYear } from '../../../../actions/yearActions';

const Years = () => {
  const dispatch = useDispatch();
  
  // Access years and chart data from Redux
  const chartData = useSelector((state) => state.chart.chartData);
  const startYear = useSelector((state) => state.year.startYear);
  const finishYear = useSelector((state) => state.year.finishYear);

  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isFinishOpen, setIsFinishOpen] = useState(false);
  const [years, setYears] = useState([]); // Store available years dynamically

  useEffect(() => {
    if (chartData && chartData.data.length > 0) {
      const uniqueYears = [...new Set(chartData.data.map(item => item.year))].sort((a, b) => a - b);
      setYears(uniqueYears);

      // Reset start and finish years in local component when a new chartData is loaded
      dispatch(setStartYear(null));
      dispatch(setFinishYear(null));
    }
  }, [chartData, dispatch]); // Re-run on chartData change to reset year selections

  const toggleStartDropdown = () => setIsStartOpen(!isStartOpen);
  const toggleFinishDropdown = () => setIsFinishOpen(!isFinishOpen);

  const handleStartSelect = (year) => {
    dispatch(setStartYear(year));
    setIsStartOpen(false);
    if (finishYear && year > finishYear) {
      dispatch(setFinishYear(null)); // Reset finish year if start year is after it
    }
  };

  const handleFinishSelect = (year) => {
    if (startYear && year >= startYear) {
      dispatch(setFinishYear(year));
      setIsFinishOpen(false);
    }
  };

  return (
    <div className="mb-years-dropdown">
      {/* Start Year Dropdown */}
      <div className="mb-years-dropdown-container">
        <button onClick={toggleStartDropdown} className="mb-years-dropdown-button">
          <span>{startYear || 'Início'}</span>
          <FaChevronDown className="mb-years-dropdown-icon" />
        </button>
        {isStartOpen && (
          <ul className="mb-years-dropdown-menu">
            {years.map((year, index) => (
              <li
                key={index}
                className="mb-years-dropdown-item"
                onClick={() => handleStartSelect(year)}
              >
                {year}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Finish Year Dropdown */}
      <div className="mb-years-dropdown-container">
        <button onClick={toggleFinishDropdown} className="mb-years-dropdown-button">
          <span>{finishYear || 'Fim'}</span>
          <FaChevronDown className="mb-years-dropdown-icon" />
        </button>
        {isFinishOpen && (
          <ul className="mb-years-dropdown-menu">
            {years.map((year, index) => (
              <li
                key={index}
                className={`mb-years-dropdown-item ${startYear && year < startYear ? 'disabled' : ''}`}
                onClick={() => handleFinishSelect(year)}
              >
                {year}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Years;
