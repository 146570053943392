import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchIndicators, setSelectedIndicator } from '../../../../actions/categoryActions';
import './Categories3.css';
import { FaChevronDown } from 'react-icons/fa';


const Categories = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Escolha uma categoria');

  // Toggle dropdown based on current state
  const toggleDropdown = () => setIsOpen(prev => !prev);

  const handleSelect = (item) => {
    console.log("Category selected:", item); // Log selected category
    setSelectedItem(item);
    setIsOpen(false);
  
    const categoryId = getCategoryId(item);
    if (categoryId) {
      dispatch(fetchIndicators(categoryId)); // Fetch indicators based on category
      dispatch(setSelectedIndicator('Escolha o indicador')); // Reset indicator placeholder
      console.log("Dispatching fetchIndicators with categoryId:", categoryId); // Log dispatch action
    }
  };

  const items = [
    'Economia',
    'Agricultura',
    'Saúde',
    'Educação',
    'Outros'
  ];

  const getCategoryId = (categoryName) => {
    switch (categoryName) {
      case 'Economia':
        return '1';
      case 'Agricultura':
        return '2';
      case 'Saúde':
        return '3';
      case 'Educação':
        return '4';
      case 'Outros':
        return '5';
      default:
        return null;
    }
  };

  return (
    <div className="mb-cat-dropdown">
      {/* Overlay only when the dropdown is open */}
      {isOpen && (
        <div
          className="mb-cat-dropdown-overlay"
          onClick={toggleDropdown} // Close dropdown when overlay is clicked
        ></div>
      )}
  
      <button
        onClick={toggleDropdown} // Use onClick on non-mobile devices
        className="mb-cat-dropdown-button"
      >
        <span className="mb-cat-dropdown-text">{selectedItem}</span>
        <FaChevronDown className="mb-cat-dropdown-icon" />
      </button>
  
      {isOpen && (
        <ul className="mb-cat-dropdown-menu">
          {items.map((item, index) => (
            <li
              key={index}
              className="mb-cat-dropdown-item"
              onClick={() => handleSelect(item)} // Handle selection with onClick only
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Categories;
