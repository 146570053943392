// src/aboutpg/AboutWrapper.js
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import About1 from './About1';
import About2 from './About2';

function About() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
  const responsabilidadeRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    // Attach the event listener for resize
    window.addEventListener('resize', handleResize);

    // Scroll to "Responsabilidade dos Dados" section if the hash is present
    if (location.hash === '#responsabilidade-dos-dados' && responsabilidadeRef.current) {
      responsabilidadeRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [location]);

  return (
    <>
      {/* Render mobile or desktop version based on screen size */}
      {isMobile ? <About2 /> : <About1 />}

      {/* Section for Responsabilidade dos Dados */}
      <div id="responsabilidade-dos-dados" ref={responsabilidadeRef}>
        {/* This section will be scrolled into view if the hash matches */}
      </div>
    </>
  );
}

export default About;
