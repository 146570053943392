// src/Dashboard3.jsx
import React from 'react';
import Header3 from './mobilebp/Header3';    // Updated path for Header component
import Indicators3 from './mobilebp/dropdowns/indicators/Indicators3'; // Updated path for Indicators component
import Years3 from './mobilebp/dropdowns/years/Years3'; // Updated path for Years component
import Downloadbtn3 from './mobilebp/Downloadbtn3';  // Updated path for Downloadbtn component
import ChartComponent3 from './mobilebp/chart/ChartComponent3'; // Updated path for ChartComponent
import SourceArea3 from './mobilebp/chart/SourceArea3'; // Updated path for SourceArea
import Footer3 from './mobilebp/Footer3';  // Updated path for Footer component
import Categories3 from './mobilebp/dropdowns/categories/Categories3';





function Dashboard3() {
    return (
      <div>
        <Indicators3 />
        <Header3 />
        <Years3 />
        <Downloadbtn3 />
        <ChartComponent3 />
        <SourceArea3 />
        <Footer3 />
        <Categories3 />
      </div>
    );
  }
  
  export default Dashboard3;