// src/dashboardpg/computerbp/Footer.jsx
import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.div`
  position: absolute;
  top: 95vh;                    // Position the footer near the bottom
  left: 50%;                    // Center horizontally
  transform: translateX(-50%);  // Adjust for perfect centering
  width: auto;                  // Adjust width based on content
  text-align: center;
  font-size: 1vw;             // Responsive font size
  color: #597695;               // Text color
`;

const Footer = () => (
  <FooterWrapper>
    DadosParaTi.com © Desenvolvido por Simão Rafique Júnior. Todos os direitos reservados.
  </FooterWrapper>
);

export default Footer;
