// src/Indicators.jsx

import React, { useState } from 'react';
import './Indicators.css';
import { FaChevronDown } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux'; // Step 1: Import useSelector and useDispatch
import { setSelectedIndicator } from '../../../../actions/categoryActions'; // Step 2: Import setSelectedIndicator action
import { fetchChartData } from '../../../../actions/chartActions'; // Import fetchChartData

import LoadingCheckIcon from '../../LoadingCheckIcon';

const Indicators = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  
  const indicators = useSelector((state) => state.category.indicators); // Step 3a: Get indicators from Redux
  const selectedIndicator = useSelector((state) => state.category.selectedIndicator); // Step 3b: Get selectedIndicator from Redux

  const dataReady = indicators && indicators.length > 0;
  
  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (item) => {
    console.log("Item ID:", item.id, "Item Name:", item.name); // Log the full item for confirmation
    dispatch(setSelectedIndicator({ id: item.id, name: item.name })); // Dispatch both id and name
    dispatch(fetchChartData(item.id));
    setIsOpen(false); // Close dropdown
  };

  return (
    <div className="pc-ind-dropdown">
      <button onClick={toggleDropdown} className="pc-ind-dropdown-button">
      <span>{selectedIndicator.name || 'Escolha o indicador'}</span> {/* Display selectedIndicator from Redux */}
      <LoadingCheckIcon dataReady={dataReady} className="loading-icon" />
      <FaChevronDown className="pc-ind-dropdown-icon" />
      </button>
      {isOpen && (
        <ul className="pc-ind-dropdown-menu">
          {indicators.map((item) => (
            <li
              key={item.id}
              className="pc-ind-dropdown-item"
              onClick={() => handleSelect(item)} // Pass selected item to handleSelect
            >
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Indicators;
