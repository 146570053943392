// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './dashboardpg/DashboardWrapper';
import About from './aboutpg/AboutWrapper';
import Feedback from './feedbackpg/FeedbackWrapper';
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/about" element={<About />} />
        <Route path="/feedback" element={<Feedback />} />
      </Routes>
      <Analytics />
    </Router>
  );
}

export default App;