// src/actions/feedbackActions.js
import axios from 'axios';

export const submitFeedback = (feedbackData) => async (dispatch) => {
  try {
    dispatch({ type: 'FEEDBACK_SUBMIT_REQUEST' });

    const { data } = await axios.post('https://orca-app-2ecqd.ondigitalocean.app/api/form/addFeedback', feedbackData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    dispatch({
      type: 'FEEDBACK_SUBMIT_SUCCESS',
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: 'FEEDBACK_SUBMIT_FAIL',
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};
