// src/Dashboard.jsx.jsx
import React from 'react';
import Sidebar from './computerbp/Sidebar';  // Updated path for Sidebar component
import Header from './computerbp/Header';    // Updated path for Header component
import Indicators from './computerbp/dropdowns/indicators/Indicators'; // Updated path for Indicators component
import Years from './computerbp/dropdowns/years/Years'; // Updated path for Years component
import Downloadbtn from './computerbp/Downloadbtn';  // Updated path for Downloadbtn component
import ChartComponent from './computerbp/chart/ChartComponent'; // Updated path for ChartComponent
import SourceArea from './computerbp/chart/SourceArea'; // Updated path for SourceArea
import Footer from './computerbp/Footer';  // Updated path for Footer component





function Dashboard1() {
    return (
      <div>
        <Header />
        <Sidebar />
        <Indicators />
        <Years />
        <Downloadbtn />
        <ChartComponent />
        <SourceArea />
        <Footer />
      </div>
    );
  }
  
  export default Dashboard1;
