// src/App.js
import React, { useState, useEffect } from 'react';
import Feedback1 from './Feedback1';
import Feedback2 from './Feedback2';

function Feedback() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    // Attach the event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? <Feedback2 /> : <Feedback1 />}
    </>
  );
}

export default Feedback;
