// src/pages/About1.jsx
import React from 'react';
import StructureContainer from './components/computertablet/StructureContainer';
import Header1 from './components/computertablet/Header1';



const Feedback1 = () => {
  return (
    <div>
      <Header1/>
      <StructureContainer />
    </div>
  );
};

export default Feedback1;
