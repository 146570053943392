// src/dashboardpg/computerbp/Sidebar.jsx
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchIndicators, setSelectedIndicator, setCategory } from '../../actions/categoryActions'; // Adjust path as necessary
import styled from 'styled-components';
import { FaChartBar, FaTractor, FaStethoscope, FaGraduationCap, FaGlobe } from 'react-icons/fa';

const SidebarWrapper = styled.div`
  position: absolute;
  top: 19.5vh;
  left: 3.03vw;
  width: 19.61vw;
  height: calc(90vh - 19.5vh);
  background-color: #fff;
  padding-left: 1.0vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SidebarTitle = styled.h2`
  font-size: 2.5vw;
  color: #597695;
  margin: 0;
  margin-bottom: 3vh;
  
`;

const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1; // This makes the list take up remaining space
`;

const NavItem = styled.li`
  display: flex;
  align-items: center;
  padding: 1.5vh 0;
  font-size: 1.8vw;
  color: #A5B6C9;
  cursor: pointer;
  color: ${(props) => (props.selected ? '#6a5acd' : '#A5B6C9')}; // Purple if selected, default otherwise
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};

`;

const NavIcon = styled.span`
  margin-right: 1.5vw;
`;

const NavArrow = styled.span`
  font-size: 1.2vw;
  margin-left: auto;
`;


const ChatbotButton = styled.button`
  background-color: #835CFF;
  color: white;
  padding: 1.5vh 1vw;
  font-size: 1.5vw;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  width: 100%; // Make button span full width of the sidebar

  &:hover {
    background-color: #597695;
  }
`;

const Sidebar = ({ mobile }) => {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Function to handle category selection
  const onCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    dispatch(setCategory(categoryId));  // Set selected category in Redux
    dispatch(setSelectedIndicator('Escolha o indicador'));
    dispatch(fetchIndicators(categoryId)); // Dispatch action to fetch indicators based on category
  };

  return (
    <SidebarWrapper>
      <SidebarTitle>Estatísticas</SidebarTitle> {/* Title stays at the top */}
      <NavList>
        <NavItem selected={selectedCategory === 1} onClick={() => onCategoryClick(1)}> {/* ID for Economia */}
          <NavIcon><FaChartBar /></NavIcon> Economia <NavArrow>›</NavArrow>
        </NavItem>
        <NavItem selected={selectedCategory === 2} onClick={() => onCategoryClick(2)}> {/* ID for Agricultura */}
          <NavIcon><FaTractor /></NavIcon> Agricultura <NavArrow>›</NavArrow>
        </NavItem>
        <NavItem selected={selectedCategory === 3} onClick={() => onCategoryClick(3)}> {/* ID for Saúde */}
          <NavIcon><FaStethoscope /></NavIcon> Saúde <NavArrow>›</NavArrow>
        </NavItem>
        <NavItem selected={selectedCategory === 4} onClick={() => onCategoryClick(4)}> {/* ID for Educação */}
          <NavIcon><FaGraduationCap /></NavIcon> Educação <NavArrow>›</NavArrow>
        </NavItem>
        <NavItem selected={selectedCategory === 5} onClick={() => onCategoryClick(5)}> {/* ID for Ambiente */}
          <NavIcon><FaGlobe /></NavIcon> Outros <NavArrow>›</NavArrow>
        </NavItem>
      </NavList>
      <ChatbotButton>{mobile ? 'Chatbot' : 'Ir Para Chatbot'}</ChatbotButton>
    </SidebarWrapper>
  );
};

export default Sidebar;