// src/dashboardpg/computerbp/Footer3.jsx
import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.div`
  position: absolute;
  top: 94vh;                    // Position the footer near the bottom
  left: 50%;                    // Center horizontally
  transform: translateX(-50%);  // Adjust for perfect centering
  width: 100%;                  // Set width to full viewport
  display: flex;                // Use flexbox to center content
  justify-content: center;      // Center the text within the div
  text-align: center;
  font-size: 2.2vw;             // Responsive font size
  color: #597695;               // Text color
  padding: 1vh 2vw;             // Optional padding for spacing
  box-sizing: border-box;
  
`;

const Footer3 = () => (
  <FooterWrapper>
    DadosParaTi.com © Desenvolvido por Simão Rafique Júnior. Todos os direitos reservados.
  </FooterWrapper>
);

export default Footer3;
