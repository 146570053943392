import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import chartConfig from './chartConfig';
import placeholderData from './placeholderData.json';
import { useSelector } from 'react-redux';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const ChartComponent = () => {
  const [chartData, setChartData] = useState(placeholderData);

  // Access dynamic titles, data, and year range from Redux
  const chartTitle = useSelector(state => state.chart.chartData.chartTitle);
  const yAxisTitle = useSelector(state => state.chart.chartData.yAxisTitle);
  const data = useSelector(state => state.chart.chartData.data);
  const startYear = useSelector(state => state.year.startYear);
  const finishYear = useSelector(state => state.year.finishYear);

  useEffect(() => {
    if (data && data.length > 0) {
      // Filter data based on selected year range
      const filteredData = data.filter(item => {
        const year = item.year;
        return (
          (!startYear || year >= startYear) && 
          (!finishYear || year <= finishYear)
        );
      });

      // Generate labels and dataset for the chart
      const labels = filteredData.map(item => item.year);
      const datasetValues = filteredData.map(item => parseFloat(item.value));

      // Create a gradient fill for the background color
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 270);
      gradient.addColorStop(0, 'rgba(131, 92, 255, 0.5)');
      gradient.addColorStop(1, 'rgba(131, 92, 255, 0)');

      // Update chartData with the filtered data and gradient
      setChartData({
        labels,
        datasets: [
          {
            label: chartTitle || placeholderData.datasets[0].label,
            data: datasetValues,
            backgroundColor: gradient,
            borderColor: placeholderData.datasets[0].borderColor,
            borderWidth: placeholderData.datasets[0].borderWidth,
            fill: true,
            tension: 0.4, // Adjusts line smoothness
          }
        ]
      });
    }
  }, [chartTitle, yAxisTitle, data, startYear, finishYear]); // Re-run effect when any of these values change

  return (
    <div style={{
      position: 'absolute',
      top: '33.5vh',
      left: '4vw',
      width: '92vw',   // 97vw - 25.3vw
      height: '44vh', // 80vh - 26.13vh
      border: '2px solid #EFF2F5',
      borderRadius: '10px',
    }}>
      <Line
        data={chartData}
        options={{
          ...chartConfig.options,
          plugins: {
            ...chartConfig.options.plugins,
            title: {
              display: true,
              text: chartTitle || 'Produto Interno Bruto de Moçambique', // Dynamic chart title from Redux or default
              font: {
                size: 14, // Set the font size for the chart title
              },
              color: '#597695',
            },
          },
          scales: {
            ...chartConfig.options.scales,
            y: {
              ...chartConfig.options.scales.y,
              title: {
                display: true,
                text: yAxisTitle || 'Bilhões de Dólares (USD)', // Dynamic y-axis title from Redux or default
                font: {
                  size: 10, // Set the font size for the y-axis title
                },
                color: '#597695',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default ChartComponent;
