// src/dashboardpg/computerbp/Downloadbtn.jsx
import React from 'react';
import styled from 'styled-components';
import { FaDownload } from 'react-icons/fa6';

const DownloadButton = styled.button`
  position: absolute;
  top: 19vh;
  left: 96vw;
  transform: translateX(-100%);
  max-width: 17vw; /* Expands leftward up to 80vw */
  font-size: 1.5vw;
  height: 1.75em;
  padding: 0.4em 1em;
  background-color: #835CFF;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  font-weight: bold;

  &:hover {
    background-color: #597695;
  }
`;

const Downloadbtn = () => {
  return (
    <DownloadButton>
      <span>Descarregar</span>
      <FaDownload />
    </DownloadButton>
  );
};

export default Downloadbtn;
