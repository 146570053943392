// src/reducers/rootReducer.js

import { combineReducers } from 'redux';
import { categoryReducer } from './categoryReducer';
import { chartReducer } from './chartReducer'; // Import chartReducer
import yearReducer from './yearReducer';
import feedbackReducer from './feedbackReducer';

const rootReducer = combineReducers({
  category: categoryReducer,
  chart: chartReducer, // Add chartReducer here
  year: yearReducer,
  feedback: feedbackReducer,
});


export default rootReducer;
