// src/reducers/yearReducer.js
const initialState = {
  startYear: null,
  finishYear: null,
};

const yearReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_START_YEAR':
      return { ...state, startYear: action.payload };
    case 'SET_FINISH_YEAR':
      return { ...state, finishYear: action.payload };
    default:
      return state;
  }
};

export default yearReducer;
