// src/Dashboard3.jsx
import React from 'react';
import Header2 from './tabletbp/Header2';    // Updated path for Header component
import Indicators2 from './tabletbp/dropdowns/indicators/Indicators2'; // Updated path for Indicators component
import Years2 from './tabletbp/dropdowns/years/Years2'; // Updated path for Years component
import Downloadbtn2 from './tabletbp/Downloadbtn2';  // Updated path for Downloadbtn component
import ChartComponent2 from './tabletbp/chart/ChartComponent2'; // Updated path for ChartComponent
import SourceArea2 from './tabletbp/chart/SourceArea2'; // Updated path for SourceArea
import Footer2 from './tabletbp/Footer2';  // Updated path for Footer component
import Categories from './tabletbp/dropdowns/categories/Categories';





function Dashboard2() {
    return (
      <div>
        <Indicators2 />
        <Header2 />
        <Years2 />
        <Downloadbtn2 />
        <ChartComponent2 />
        <SourceArea2 />
        <Footer2 />
        <Categories />
      </div>
    );
  }
  
  export default Dashboard2;