// src/reducers/categoryReducer.js

const initialState = {
  indicators: [], // Initial empty array for indicators
  selectedIndicator: { id: null, name: 'Escolha o indicador' }, // Store both id and name
  selectedCategory: null, // Track the currently selected category
  loading: false, // Track loading state for fetching indicators
};

export const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INDICATORS':
      return { ...state, indicators: action.payload };

    case 'SET_SELECTED_INDICATOR':
      return { ...state, selectedIndicator: action.payload }; // Store the object with id and name

    case 'SET_CATEGORY':
      return { ...state, selectedCategory: action.payload, indicators: [] }; // Clear indicators on new category selection

    case 'SET_LOADING':
      return { ...state, loading: action.payload }; // Update loading state

    default:
      return state;
  }
};
