// src/reducers/chartReducer.js

const initialState = {
    loading: false,
    chartData: {
      chartTitle: '',
      yAxisTitle: '',
      citation: '',
      data: [],
    },
    error: null,
  };
  
  export const chartReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'CHART_DATA_REQUEST':
        return { ...state, loading: true, error: null };
        
      case 'CHART_DATA_SUCCESS':
        return {
          ...state,
          loading: false,
          chartData: {
            chartTitle: action.payload.chartTitle,
            yAxisTitle: action.payload.yAxisTitle,
            citation: action.payload.citation,
            data: action.payload.data,
          },
        };
  
      case 'CHART_DATA_FAIL':
        return { ...state, loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  