// src/pages/About1.jsx
import React from 'react';
import StructureContainer from './components/computertablet/StructureContainer';
import Header2 from './components/mobile/Header2';

const Feedback2 = () => {
  return (
    <div>
      <Header2 />
      <StructureContainer />
    </div>
  );
};

export default Feedback2;
