// src/components/StructureContainer.jsx
import React, { useState, useEffect } from 'react';
import './StructureContainer.css';
import { useDispatch, useSelector } from 'react-redux';
import { submitFeedback } from '../../../actions/feedbackActions';

const StructureContainer = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const feedbackLoading = useSelector((state) => state.feedback.loading);
  const feedbackSuccess = useSelector((state) => state.feedback.success);
  const feedbackError = useSelector((state) => state.feedback.error);

  // Clear form fields on successful feedback submission
  useEffect(() => {
    if (feedbackSuccess) {
      setName('');
      setEmail('');
      setMessage('');
    }
  }, [feedbackSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(submitFeedback({ name, email, message }));
  };

  return (
    <div className="fb-structure-container">
      <div className="contact-info">
        <h2>Informações de Contacto</h2>
        <p>Entre em contacto com nossa equipe!</p>
        <ul>
          <li>📞 +258 84 420 345</li>
          <li>📞 +44 754 383 1339</li>
          <li>📧 rsimao60@gmail.com</li>
        </ul>
      </div>
      <div className="feedback-form">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label>Nome (Opcional)</label>
              <input
                type="text"
                placeholder="John Doe"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={feedbackLoading} // Disable input if loading
              />
            </div>
          </div>
          <div className="form-group">
            <label>Email (Opcional)</label>
            <input
              type="email"
              placeholder="exemplo@exemplo.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={feedbackLoading} // Disable input if loading
            />
          </div>
          <div className="form-group">
            <label>Mensagem de Feedback</label>
            <textarea
              className="message-textarea"
              placeholder="Escreva aqui seu feedback..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              disabled={feedbackLoading} // Disable textarea if loading
            ></textarea>
          </div>
          <button type="submit" disabled={feedbackLoading}> {/* Disable button if loading */}
            {feedbackLoading ? 'Enviando...' : 'Enviar Feedback'}
          </button>
        </form>
        {feedbackSuccess && <p className="success-message">Feedback enviado com sucesso!</p>}
        {feedbackError && <p className="error-message">Erro ao enviar o feedback: {feedbackError}</p>}
      </div>
    </div>
  );
};

export default StructureContainer;
