// src/dashboardpg/computerbp/chart/SourceArea.jsx
import React from 'react';
import { useSelector } from 'react-redux';

const SourceArea2 = () => {
  
  const citation = useSelector(state => state.chart.chartData.citation);
  
  return (
    <div>
      {/* Main Source Box */}
      <div style={{
        position: 'absolute',
        top: '82vh',
        left: '4vw',
        width: '92.5vw',  // 70.7vw - 25.3vw
        height: '10vh',    // 92vh - 82vh
        border: '2px solid #EFF2F5',  // Define border color and thickness
        borderRadius: '8px',          // Optional rounded corners
        padding: '1vw',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#f9f9f9'
      }}>
        <span style={{
        fontSize: '1.5vw',               // Responsive font size
        fontWeight: 'bold',
        color: '#597695',
        whiteSpace: 'normal',             // Allow text to wrap within the box
        overflow: 'hidden',               // Prevent overflow
        textOverflow: 'ellipsis',         // Show ellipsis if text overflows
        wordBreak: 'break-word',          // Break long words to fit within the box
      }}>
      Fonte: {citation || "MacroTrends. (2024). Mozambique GDP - Gross Domestic Product 1960-2023."} {/* Display citation on the same line */}
      </span>

      </div>

      {/* Explanatory Text below the box */}
      <p style={{
        position: 'absolute',
        top: '92vh',  // Place right below the SourceArea box
        left: '4vw',
        width: '92vw',
        fontSize: '1.4vw',
        color: '#597695',
        margin: '0'
      }}>
        Curioso (a) sobre fontes e atribuição de dados? clique em 'Responsabilidade dos Dados' no menu principal.
        Citação baseada no modelo Harvard.
      </p>
    </div>
  );
};

export default SourceArea2;
