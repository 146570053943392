// Categories.jsx
import React, { useState } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch
import { fetchIndicators, setSelectedIndicator } from '../../../../actions/categoryActions'; // Import fetchIndicators action
import './Categories.css';
import { FaChevronDown } from 'react-icons/fa'; // For the chevron icon

const Categories = () => {
  const dispatch = useDispatch(); // Initialize dispatch
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Escolha uma categoria');

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (item) => {
    setSelectedItem(item);
    setIsOpen(false);

    // Dispatch the action to fetch indicators for the selected category
    const categoryId = getCategoryId(item); // Get the category ID based on the selected item
    if (categoryId) {
      dispatch(fetchIndicators(categoryId)); // Dispatch the action to fetch indicators
      dispatch(setSelectedIndicator('Escolha o indicador'));
    }
  };

  const items = [
    'Economia',
    'Agricultura',
    'Saúde',
    'Educação',
    'Outros'
  ];

  const getCategoryId = (categoryName) => {
    // Map category names to their corresponding IDs
    switch (categoryName) {
      case 'Economia':
        return '1'; // Replace with actual ID
      case 'Agricultura':
        return '2'; // Replace with actual ID
      case 'Saúde':
        return '3'; // Replace with actual ID
      case 'Educação':
        return '4'; // Replace with actual ID
      case 'Outros':
        return '5'; // Replace with actual ID
      default:
        return null; // Handle default case
    }
  };

  return (
    <div className="tb-cat-dropdown">
      <button onClick={toggleDropdown} className="tb-cat-dropdown-button">
        <span className="tb-cat-dropdown-text">{selectedItem}</span>
        <FaChevronDown className={`tb-cat-dropdown-icon ${isOpen ? 'open' : ''}`} />
      </button>
      {isOpen && (
        <ul className="tb-cat-dropdown-menu">
          {items.map((item, index) => (
            <li
              key={index}
              className="tb-cat-dropdown-item"
              onClick={() => handleSelect(item)} // Handle selection
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Categories;