// src/LoadingCheckIcon.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import { FaCheckCircle, FaSpinner } from 'react-icons/fa';

const LoadingCheckIcon3 = () => {
  // Access loading and indicators from Redux
  const loading = useSelector((state) => state.category.loading);
  const indicators = useSelector((state) => state.category.indicators);
  const selectedCategory = useSelector((state) => state.category.selectedCategory);

  // Only show the icon if a category has been selected
  if (!selectedCategory) return null;

  const dataReady = !loading && indicators.length > 0;

  const iconStyle = {
    fontSize: '1.3em', // Adjust icon size
    animation: loading ? 'spin 1s linear infinite' : 'none',
  };

  return (
    <>
      <span className="loading-icon" style={iconStyle}>
        {dataReady ? <FaCheckCircle /> : <FaSpinner />}
      </span>
    </>
  );
};

// Define the spin animation in JSX
const spinAnimation = document.createElement('style');
spinAnimation.innerHTML = `
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
document.head.appendChild(spinAnimation);

export default LoadingCheckIcon3;
