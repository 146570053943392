// src/reducers/feedbackReducer.js
const initialState = {
    loading: false,
    success: false,
    error: null,
  };
  
  const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FEEDBACK_SUBMIT_REQUEST':
        return { ...state, loading: true, success: false, error: null };
      case 'FEEDBACK_SUBMIT_SUCCESS':
        return { ...state, loading: false, success: true, error: null };
      case 'FEEDBACK_SUBMIT_FAIL':
        return { ...state, loading: false, success: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default feedbackReducer;
  