import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { MdThumbsUpDown } from "react-icons/md";
import Logo from './logo.jpeg';

// Styled components

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 16.3vh; /* Header height */
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 3.65vw;                 // Position LogoContainer absolutely within its parent
  display: flex;                // Use flex layout
  align-items: center;          // Center vertically
  gap: 1.5vw;                     // Add space between logo and text

  img {
    height: 80%;
    max-height: 10vh;
  }
`;

const LogoText = styled.span`
font-size: 1.5vw;
font-weight: bold;
color: #597695;
`;

const NavLinks = styled.nav`
  position: absolute;
  left: 25vw; /* Sobre Nos starts at 25.45vw */
  display: flex;
  gap: 6vw; /* Space between the two links */
  font-size: 1.5vw;
  
  a {
    color: #A5B6C9;
    text-decoration: none;
    font-weight: 500;
    &:hover {
      color: #835CFF;
    }
  }
`;

const FeedbackLink = styled(NavLink)`
  text-decoration: none; /* Remove underline */
`;

const FeedbackBtn = styled.div`
  position: relative;
  left: 96vw;
  width: 15vw;
  transform: translateX(-100%);
  display: flex;
  align-items: center;
  height: 3em;
  justify-content: center;
  gap: 1vw;
  padding: 0.5vw 1vw;
  background-color: #EFF2F5;
  color: #597695;
  cursor: pointer;
  border-radius: 0.5vw;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Shadow for the user container */
  
  span {
    font-size: 1.5vw;
    font-weight: bold;
    
  }

  &:hover {
    background-color: #835CFF;
    color: white;
  }
`;

// Component

const Header = () => {
  return (
    <>
      <HeaderContainer>
        {/* Logo section */}
        <LogoContainer>
          <img src={Logo} alt="Logo" />
          <LogoText>Dados Para Ti</LogoText>
        </LogoContainer>

        {/* Navigation links */}
        <NavLinks>
          <NavLink to="/about" activeClassName="active">
            Sobre Nós
          </NavLink>
          <NavLink to="/about#responsabilidade-dos-dados" activeClassName="active">
            Responsabilidade dos Dados
          </NavLink>
        </NavLinks>

        {/* User profile container */}
        <FeedbackLink to="/feedback" activeClassName="active">
          <FeedbackBtn>
            <span>Deixar Feedback</span>
            <MdThumbsUpDown size={'2vw'} />
          </FeedbackBtn>
        </FeedbackLink>
      </HeaderContainer>
    </>
  );
};

export default Header;
