// src/App.js
import React, { useState, useEffect } from 'react';
import Dashboard1 from './Dashboard1';
import Dashboard2 from './Dashboard2';
import Dashboard3 from './Dashboard3';


function Dashboard() {
  const [deviceType, setDeviceType] = useState('pc');

  useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth <= 480) {
        setDeviceType('mobile');
      } else if (window.innerWidth <= 1024) {
        setDeviceType('tablet');
      } else {
        setDeviceType('pc');
      }
    };

    updateDeviceType(); // Set initial device type
    window.addEventListener('resize', updateDeviceType); // Listen for resize events

    return () => window.removeEventListener('resize', updateDeviceType); // Cleanup on unmount
  }, []);

  return (
    <div>
      {deviceType === 'pc' && <Dashboard1 />}
      {deviceType === 'tablet' && <Dashboard2 />}
      {deviceType === 'mobile' && <Dashboard3 />}
    </div>
  );
}

export default Dashboard;
